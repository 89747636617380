.order {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(185, 185, 185, 0.6);
  mix-blend-mode: normal;
  backdrop-filter: blur(111.45px);

  font-size: 16px;
  line-height: 21px;
  color: #F4F4F4;
}
