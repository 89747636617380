div.content {

  & .name {
    padding: 4px;
    text-align: center;
  }

  & .controls {
    padding: 4px;
    display: flex;

    button + button {
      margin: 0 4px;
    }

    div {
      align-self: center;
    }

    input {
      padding: 0 4px;
      width: 50px;
      border-bottom: 1px solid #111;
      margin-left: 4px;
    }
  }

  button.disabled {
    outline: none;
    svg path {
      color: #c6c6c6;
    }
  }
}