@import "src/styles/mixins";

.jobCard {
  width: 230px;
  height: 400px;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin: 0 1rem 1rem;
  position: relative;

  .content {
    width: calc(100% - 2rem);
    @include in-center-Y;

    .title, .state {
      line-height: 26px;
      font-size: 16px;
      text-align: center;
    }

    .title {
      font-weight: 700;
    }

    .progress {
      @include progress-bar;
      margin-top: 10px;

      & > div {
        transition: width 1s;
      }
    }
  }

}
