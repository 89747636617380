.toolbar {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
}

.light {
  .btn {
    svg {
      fill: #000;
    }

    &:hover {
      background-color: #e5e5e5;
    }
  }

  .navigationInnerContainer {
    div {
      color: #000000;

      svg {
        fill: #000000;
      }
    }
  }
}

.dark {
  .btn {
    svg {
      fill: #f0f0f0;
    }

    &:hover {
      background-color: #353535;
    }
  }

  .navigationInnerContainer {
    div {
      color: #fff;

      svg {
        fill: #fff;
      }
    }
  }
}

.light button, .dark button {
  width: 40px;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  padding: 8.5px 9.5px;
}


.tooltip {
  button:first-child {
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
  }

  button:last-child {
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
  }
}

.tooltip__dark {
  background-color: #353535;

  span {
    border-bottom-color: #353535 !important;
  }

  button:hover {
    background-color: #555555;
  }
}

.tooltip__light {
  button:hover {
    background-color: #e5e5e5;
  }
}

.overflow-menu-options__light,
.overflow-menu-options__dark {
  overflow: auto;
  max-height: calc(100vh - 120px);
  li {
    min-height: 2.5rem;
  }
}

.overflow-menu-options__light {
  background-color: #f0f0f0;
  li {
    button {
      color: #000;
    }
    button[disabled] {
      color: #4f4f4f
    }
  }
  li:hover {
    background-color: #e5e5e5;
    button {
      color: #000;
    }
    button[disabled] {
      color: #4f4f4f
    }
  }
}

.navigation {
  margin-bottom: 16px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 18px;
  z-index: 300;
  display: flex;
  justify-content: space-around;

  .navigationInnerContainer {
    display: flex;
    align-items: center;

    div {
      padding: 0 20px;
    }

    .disabled {
      opacity: 0.5;
    }
  }
}


button.listen {
  padding: 4px;

  div {
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;
    border: 1px solid #ffffff;
    padding: 0 1rem;
  }
}

.dark {
  .listen {
    background-color: transparent;
    div {
      border: 1px solid #f0f0f0;
      color: #f0f0f0;

      svg {
        fill: #f0f0f0;
      }
    }
  }

  .listen:hover {
    background-color: #353535;
  }
}

.light {
  .listen {
    background-color: #ffffff;
    color: #000000;
    div {
      border: 1px solid #000000;
    }
  }

  .listen:hover {
    background-color: #e5e5e5;
  }
}

.ovbtn {
  width: 100%;
  height: 100%;
  position: relative;
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
