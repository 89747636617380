@import "common";

.recorder {
  @include widget;

  .close {
    width: 100%;
    height: 20px;
    display: flex;
    justify-content: flex-end;
    padding: {
      top: 20px;
      right: 20px;
    };
    svg {
      cursor: pointer;
    }

    &:hover svg path {
      opacity: 0.5;
    }
  }

  .button {
    height: 72px;
    margin-top: 36px;
    display: flex;
    justify-content: center;

    svg {
      cursor: pointer;
    }

    div:nth-child(2n):hover svg {
      opacity: 0.5;
    }

    div:nth-child(2n + 1) {
      display: flex;
      justify-content: center;
      flex-grow: 1;
      align-items: center;
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
    }

    div {
      align-self: center;
    }
  }

  .hint {
    display: flex;
    justify-content: center;
    margin-top: 31px;

    font: {
      weight: 600;
      size: 13px;
    }
  }
}

.recording {
  background: linear-gradient(90deg, #EE432C 0%, #EC2555 100%);
}
