.inputRow {
  display: flex;
  margin-bottom: 24px;

  div:first-child {
    display: inline-flex;
    align-self: center;
    font-size: 20px;
    line-height: 26px;
    padding-right: 86px;
  }

  div:last-child > div {
    padding-right: 0;
  }
}

button.submit {
  width: 203px;
}

.error {
  color: #fa4d56;
  margin-top: 0.5rem;
}

@media (max-width: 25rem) {
  .inputRow > div {
      padding-right: 0.5rem !important;
  }
}
