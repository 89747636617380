$black: #0F0F0F;
$black-light: #5C5F63;
$black-lighter: #242425;

$gray: #6A6D73;

$red-light: #FF9882;
$red: #B11309;

$blue: #094CB1;
$blue-light: #82C3FF;

$white: #FFFFFF;

@mixin widget {
  background-color: $black-lighter;
  border-radius: 20px;
  display: flex;
  width: 312px;
  flex-direction: column;
  padding-bottom: 30px;
}
