.container {
  display: flex;
  flex-direction: row-reverse;
}

.half {
  flex-basis: 50%;
}

.avatarContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.link {
  margin-top: 20px;
}

@media (max-width: 66rem) {
  .half {
    flex-basis: 100%;
  }
  .container {
    flex-wrap: wrap;
  }
}
