
// Text Styles
.h1 {
    font-size: 42px;
}

.h2 {
    font-size: 28px;
    line-height: 36px;
}

.h2Subtitle {
    font-size: 18px;
}

.bodyText1 {
    font-size: 18px;
    line-height: 23px;
    letter-spacing: unset;
    font-weight: unset;
}

.bodyText2 {
    font-size: 18px;
    line-height: 23px;
    letter-spacing: unset;
    font-weight: unset;
}