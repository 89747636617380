.login, .forgot {
  fieldset {
    margin-bottom: 0.5rem;
  }
}

.forgotLink {
  display: inline-flex;
  width: 100%;
}

.createButton {
  width: 100%;
}

.center {
  text-align: center;
}

.error {
  margin-top: 0;
  margin-bottom: 0;
  min-width: 0;
}

@media (max-width: 42rem) {
  .forgotLink {
    justify-content: center;
  }
}
