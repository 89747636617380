.base {
  padding-top: 24px;

  .title {
    display: flex;
    justify-content: space-between;
    height: 2rem;

    div:first-child {
      display: flex;
      align-items: center;
      font-weight: 600;
      line-height: 22px;
      font-size: 16px;
    }
  }

  .commentBlock {
    padding-top: 1rem;

    .symbols {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.5rem;
    }

    .submitButtons {
      display: flex;
      flex-direction: row-reverse;
      margin-top: 0.5rem;

      button:last-child {
        margin-right: 1rem;
      }
    }
  }

  .comments {

    .loader {
      display: flex;
      justify-content: center;
    }

    .noComments {
      display: flex;
      min-height: 2rem;
      align-items: center;
    }
  }


}
