.passHelperText {
  margin-top: -28px;;
}

.createButton {
  width: 100%;
}

.error {
  margin-top: 0;
  margin-bottom: 0;
  min-width: 0;
}

@media (max-width: 42rem) {
  .already {
    text-align: center;
  }
}
