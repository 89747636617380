.commentItem {
  padding-bottom: 32px;

  .author {
    margin-bottom: 12px;
  }

  .text {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.16px;
    white-space: pre-wrap;
  }
}
