.uploadTitle {
  margin: 0 0 0.5rem;
}

.uploader {
  height: 40px;
}

.orAddText {
  margin: 1rem 0;
}

@media (min-width: 42rem) {
  .orAddText {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }
}
