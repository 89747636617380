.content {
  --lrGap: 0px;
  max-width: 1024px;
  padding: 0 var(--lrGap);

  .collageContainer {
    width: 100%;
    --wds: min(1024px, 100vw - calc(var(--lrGap) * 2));
    height: calc(var(--wds) / 2.3333);

    display: grid;
    grid-template-columns: repeat(24, 1fr);
    position: relative;

    .collageImage1 {
      grid-column: 1 / span 4;
      grid-row: 1;
      padding-top: 65%;
      z-index: 1;
    }
    .collageImage2 {
      grid-column: 4 / span 5;
      grid-row: 1;
      padding-top: 0;
      z-index: 2;
    }
    .collageImage3 {
      grid-column: 8 / span 6;
      grid-row: 1;
      padding-top: 20%;
      z-index: 1;
    }
    .collageImage4 {
      grid-column: 13 / span 5;
      grid-row: 1;
      padding-top: 50%;
      z-index: 1;
    }
    .collageImage5 {
      grid-column: 16 / span 5;
      grid-row: 1;
      padding-top: 30%;
      z-index: 0;
    }
    .collageImage6 {
      grid-column: 20 / span 5;
      grid-row: 1;
      padding-top: 50%;
      z-index: 1;
    }

    img {
      width: 100%;
    }
  }
}