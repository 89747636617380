.authReq {
  width: 312px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  border-radius: 5px;

  div {
    text-align: center;
    font-size: 16px;
    line-height: 22px;
  }

  .signUp {
    padding-top: 1rem;
    padding-bottom: 0.5rem;

    button {
      min-height: auto;
      height: 2rem;
      width: 168px;
      padding: 0;
      justify-content: space-around;
    }
  }

  .signIn {
    font-size: 12px;
    line-height: 16px;
  }
}

.dark.authReq {
  background-color: #262626;
}

.light.authReq {
  color: #000;
  background-color: #F4F4F4;
}