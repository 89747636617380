.content {
  padding: 0 1rem;
  padding-bottom: 40px;
}

.title {
  margin-bottom: 0.5rem;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.16px;
  white-space: pre-wrap;
}

.charts {
  padding-bottom: 1rem;
}

.chart {
  width: calc(50% - 4rem);
  display: inline-block;
  margin-right: 4rem;
}

.dropdownBlock {
  width: 100%;
  display: inline-block;
}

.dropdown {
  max-width: 150px;
}

@media (max-width: 900px) {
  .chart {
    width: 100%;
  }
}

.info {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #db2663;
  margin-bottom: 1rem;

  svg {
    stroke: #db2663;
    fill: #db2663;
    margin-right: 0.5rem;
  }
}

.howto {
  h3 {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 8px;
  }

  ol {
    padding: 20px; 
    list-style-type: decimal;
    color: #707680;
    font-size: 15px;
  }

  ol li {
      margin: 0 10px;
      padding: 0 10px;
      padding-bottom: 8px;
      p {
        color: white;
      }
  }

  background-color: #242425;
  border-width: 3px;
  border-radius: 10px;
  padding: 16px;
  min-height: 100%;
  vertical-align: top; /* here */
  margin-bottom: 20px;
}

.refferal {
  margin-top: 16px;
  position: relative;
  border-color: #6340EF;
  border-style: solid;
  border-width: 3px;
  border-radius: 16px;
  height: 32px;
  line-height: 26px;
  padding-left: 16px;
  padding-right: 16px;

  .button {
    position: absolute;
    right: -3px;
    top: -3px;
    border-color: #6340EF;
    background-color: #6340EF;
    border-style: solid;
    border-width: 3px;
    border-radius: 16px;
    height: 32px;
    width: 80px;
    line-height: 26px;
    text-align: center;
    color: white;
    cursor: pointer;
  }
}