.ck-editor__editable_inline {
    height: var(--ckh, 200px);
    max-height: var(--ckh, 200px);;
}

.ck-content soundeffect {
    margin: 0 4px;
}

.ck-content soundeffect::after {
    content: '';
    vertical-align: sub;
    width: 16px;
    height: 16px;
    color: #6f6f6f;
    border-radius: 3px;
    border: 1px solid #6f6f6f;
    padding: 1px;
    display: inline-block;
    background-image: url("data:image/svg+xml;base64,PHN2ZyBpZD0iaWNvbiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMzIgMzIiPjxkZWZzPjxzdHlsZT4uY2xzLTF7ZmlsbDojRkY3NDAwO30uY2xzLTJ7ZmlsbDpub25lO308L3N0eWxlPjwvZGVmcz48dGl0bGU+dm9sdW1lLS11cDwvdGl0bGU+PHBhdGggY2xhc3M9ImNscy0xIiBkPSJNMjcuMTYsOC4wOCwyNS42Myw5LjM3YTEwLDEwLDAsMCwxLS4yOSwxMy4yM0wyNi44MSwyNGExMiwxMiwwLDAsMCwuMzUtMTUuODhaIi8+PHBhdGggY2xhc3M9ImNscy0xIiBkPSJNMjEuNTgsMTJhNiw2LDAsMCwxLS4xOCw3Ljk0bDEuNDcsMS4zNmE4LDgsMCwwLDAsLjIzLTEwLjU5WiIgLz48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik0xOCwzMGExLDEsMCwwLDEtLjcxLS4zTDkuNjcsMjJIM2ExLDEsMCwwLDEtMS0xSDJWMTFhMSwxLDAsMCwxLDEtMUg5LjY3bDcuNjItNy43YTEsMSwwLDAsMSwxLjQxLDBBMSwxLDAsMCwxLDE5LDNWMjlBMSwxLDAsMCwxLDE4LDMwWk00LDIwaDYuMDhhMSwxLDAsMCwxLC43MS4zTDE3LDI2LjU3VjUuNDNMMTAuNzksMTEuN2ExLDEsMCwwLDEtLjcxLjNINFoiLz48cmVjdCBpZD0iX1RyYW5zcGFyZW50X1JlY3RhbmdsZV8iIGRhdGEtbmFtZT0iJmx0O1RyYW5zcGFyZW50IFJlY3RhbmdsZSZndDsiIGNsYXNzPSJjbHMtMiIgd2lkdGg9IjMyIiBoZWlnaHQ9IjMyIi8+PC9zdmc+Cg==");
}

.ck-fm-editor .ck-dropdown .ck-toolbar_vertical .ck-toolbar__items {
    max-height: calc(var(--ckh, 200px) - 100px);
    overflow-y: auto;
    overflow-x: hidden;
    flex-wrap: nowrap;
}

.ck-fm-editor .ck-dropdown .ck-toolbar_vertical .ck-toolbar__items button:last-child {
    margin-bottom: 40px !important;
}

.ck-content i {
    font-style: italic;
}