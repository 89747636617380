.controlsContainer {
  justify-content: center;

  .controls {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    position: relative;
    padding: 2px;

    & div {
      align-self: center;

      &:hover > svg path {
        opacity: 0.5;
      }
    }

    .play {
      cursor: pointer;
      width: 100%;
      height: 100%;
    }

    .pause {
      vertical-align: middle;
      margin-top: 3px;
      margin-left: 3px;
    }
    
    .disabled {
      cursor: default;
      opacity: 0.5;
    }
    .progress {
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }
}