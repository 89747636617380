@import '@carbon/themes/scss/themes';

$carbon--theme: $carbon--theme--g100;
@include carbon--theme();

.tagsList {
  font-size: 20px;
  color: $text-02;
  line-height: 68px;

  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
}

.tagItem {
  word-spacing: 12px;
  float: left;
  margin-right: 12px;
}

.tag {
  font-size: 20px;
  background-color: #6F6F6F7F;
  padding: 8pt 11pt 8pt 11pt;
  border-radius: 8pt;
  word-spacing: 0px;
  cursor: pointer;
}

/* Use a media query to add a breakpoint at 642px: */
@media screen and (max-width: 642px) {
  .tagsList {
    font-size: 14px;
    word-spacing: 10px;
    line-height: 58px;
  }

  .tag {
    font-size: 14px;
    padding: 8pt 8pt 8pt 8pt;
  }

  .title {
    font-size: 26px;
  }  
}
