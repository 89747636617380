.sharing {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  color: #000000;

  .text {
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    margin-bottom: 11px;
  }

  .socialButtons {
    display: flex;
    margin-bottom: 11px;
    justify-content: space-around;
    padding-bottom: 0;

    .socialItem {
      display: flex;
      flex-direction: column;
      margin-right: 0;
      padding-top: 0;
      margin-top: 0;

      .shareButton {
        cursor: pointer;
      }

      .shareButton:hover:not(:active) {
        opacity: 0.75;
      }

      .shareCount {
        align-self: center;
        max-width: 32px;
      }
    }
  }
}
