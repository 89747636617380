@import "common";

.paragraph {
  background-color: $black-lighter;
  // border: 3px solid $black-lighter;
  border-radius: 1rem;
  padding: 1.5rem;

  font: {
    family: Roboto;
    size: 1rem;
  }

  line-height: 1.625rem;
  position: relative;

  .badges {
    position: absolute;
    display: flex;
    top: -8px;
    left: -24px;
  }

  .icon {
    position: absolute;
    &:hover {
      opacity: 0.5;
      cursor: pointer;
    }

    &.disabled {
      opacity: 0.2;
      cursor: default;
    }
  }

  .iconAdd, .iconPlay {
    right: -34px;
    top: 30px;
  }

  .iconRecord {
    right: -68px;
    top: 30px;
  }
}

.isUploading, .selected {
}

.selected {
  background: linear-gradient(90deg, #6340EF 0%, #8348FF 100%);
}

.disabled {
  background-color: $black;
  color: $black-light;
}
