@mixin progress-bar {
  width: 100%;
  height: 2px;
  background-color: #e0e0e0;
  position: relative;

  div {
    height: 2px;
    background-color: #0F62FE;
  }
}

@mixin in-center-Y {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
