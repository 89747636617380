.titleWrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 1.5rem 1rem;

  .title {
    flex-grow: 1;
    flex-shrink: 1;
  }

  .sort {
    select {
      background-color: #161616;
      border-bottom: 0;
    }
  }
}

.page {
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  height: calc(100vh - 3rem);
}

.content {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding-bottom: 2rem;
  overflow: hidden;

  ::-webkit-scrollbar {
    width: 10px;
    background: transparent;
    margin-left: 0;
  }

  ::-webkit-scrollbar-button {
    display: none;
  }

  ::-webkit-scrollbar-track-piece {
    background-color: #171717;
  }

  ::-webkit-scrollbar-thumb {
    height: auto;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 4px;
  }
}


