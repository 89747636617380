@import '@carbon/themes/scss/themes';

$carbon--theme: $carbon--theme--g100;
@include carbon--theme();

.sectionContent {
  padding-bottom: 1.5rem;
}

.content {
  padding-top: 1.6rem;
}

/* Use a media query to add a breakpoint at 642px: */
@media screen and (max-width: 642px) {
  .content {
    padding-top: 1.1rem;
  }
  
  .sectionContent {
    padding-bottom: 0.8rem;
  }
}
