@import "common";

.badge {
  border-radius: 12px;
  text-transform: uppercase;
  font-size: 12px;
  max-height: 24px;
  display: flex;
  align-items: center;
  font-weight: bold;

  padding: 4px;
  padding: {
    right: 10px;
  }

  div:first-child {
    font-size: 1rem;
  }

  div {
    height: 24px;
  }

  div + div {
    margin-left: 6px;
  }
}

.red {
  color: $red;
  background-color: $red-light;
}

.blue {
  color: $blue;
  background-color: $blue-light;
}

.badge + .badge {
  margin-left: 10px;
}
