.chapter {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  justify-content: space-between;
  height: 2rem;
  align-items: center;

  .name {
    flex-shrink: 1;
    line-height: 2rem;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    fill: #fff;
  }

  .onChapterButton {
    padding: 0;
  }

  button {
    display: none;
  }

  button + button {
    margin-left: 18px;
  }

  .pagesCount {
    font-size: 16px;
    margin-left: 0.5rem;
  }

  .buyButton {
    display: initial;
    padding-right: 0;
    border: 0;
    outline: none;
  }

  .buyButton:hover {
    background-color: transparent;
  }

  &:hover {

    .pagesCount {
      display: none;
    }

    button {
      display: initial;
    }
  }
}
