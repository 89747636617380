.banner {
  width: 100%;
  margin-top: 3rem;

  .image {
    width: 100%;
    height: calc(100vw/3.7);
    max-height: 370px;
  }
}
