.continueReadingItem {
  margin-right: 1rem;
  border-radius: 5px;
  height: 122px;
  max-width: 309px;
}

.newThisWeekItem {
  margin-right: 1rem;
  border-radius: 5px;
  height: 225px;
  max-width: 521px;
}

.trendingItem {
  margin-right: 1rem;
  border-radius: 5px;
  height: 455px;
  max-width: 230px;
}

.content {
  padding-top: 1rem;
}

.section {
  padding-bottom: 1.5rem;
}

/* Use a media query to add a breakpoint at 642px: */
@media screen and (max-width: 642px) {
  .content {
    padding-top: 0.8rem;
  }
  .section {
    padding-bottom: 1rem;
  }
}