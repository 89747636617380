.content {
  font-family: IBM Plex Sans;
  font-weight: 500;
  font-size: 16px;

  .center {
    text-align: center;
  }

  .h1 {
    font-size: 36px;
    line-height: 47px;
    margin-bottom: 45px;
  }

  .registerButton {
    width: 200px;
    height: 48px;
    border: 0;
    border-radius: 24px;
    background: #2377FF;
    color: #FFFFFF;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
  }

  .contactUs {
    border: 0;
    background: 0;
    color: #5596FF;
    cursor: pointer;
    margin-bottom: 80px;
    font-size: 16px;
    font-weight: 500;
  }

  .block {
    h2 {
      font-weight: bold;
      font-size: 24px;
      line-height: 31px;
    }

    svg + h2 {
      margin-top: 9px;
    }

    h2 + p, p + p {
      margin-top: 22px;
    }

    .yellow {
      color: #FED301;
    }

    .green {
      color: #04C382;
    }

    .blue {
      color: #6DA4FD;
    }

    p a {
      cursor: pointer;
      text-decoration: none;
    }

    .buttonAsLink {
      border: 0;
      background: 0;
      cursor: pointer;
    }
  }

  .block + .block {
    margin-top: 72px;
  }
}