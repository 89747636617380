
.item {
  padding-bottom: 32px;
  width: 100%;
}

/* Use a media query to add a breakpoint at 642px: */
@media screen and (max-width: 642px) {
  .item {
    padding-bottom: 24px;;
  }
}
