@import "common";

.player {
  @include widget;
  padding: 20px;

  .buttons {
    display: flex;

    div:first-child {
      flex-grow: 1;
      div {
        display: inline-block;
      }
    }

    div:last-child {
      display: flex;
      a + div {
        margin-left: 24px;
      }
    }

    div > svg {
      cursor: pointer;
    }

    div:hover > svg path {
      opacity: 0.5;
    }
  }

  .controlsContainer {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    .controls {
      display: flex;

      & div {
        align-self: center;

        &:hover > svg path {
          opacity: 0.5;
        }
      }

      .play {
        margin: 0 34px;
        cursor: pointer;
        width: 56px;
        height: 56px;
      }

      .disabled {
        cursor: default;
        opacity: 0.5;
      }
    }
  }

  .seekerContainer {
    display: flex;
    flex-direction: column;
    margin-top: 34px;

    .seekerWrap {
      width: 100%;
      height: 20px;
      padding-top: 7px;
      cursor: pointer;

      .seeker {
        background: rgba(15, 15, 15, 1);
        border-radius: 3px;
        width: 100%;
        height: 6px;

        .time {
          height: 100%;
          background: rgba(99, 64, 239, 1);
        }
      }
    }

    .times {
      display: flex;
      justify-content: space-between;
      font-weight: bold;
    }
  }
}
