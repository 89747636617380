.h2Subtitle {
    color: #8c8c8c;
}

.bodyColor {
    color: black;
}

.contentColor {
    background-color: white;
}

.content {
    filter: unset;
}


.button_cta {
    background-color: #2C66DA;
}

.button_normal {
    background-color: #343537;
}