.breadcrumbContainer {
  ol {
    display: flex;
    flex-wrap: nowrap;

    li.breadcrumbItem {
      & > a {
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.actionButton {
}

@media (max-width: 30rem) {
  .actionButton {
    padding-left: 4px;
    padding-right: 4px;
  }
}
