.player {
  width: 100%;
  margin-top: 10px;

  .controls {
    display: flex;
    justify-content: space-between;

    svg {
      fill: #ffffff;
    }

    svg:hover {
      fill: #f4f4f4;
      cursor: pointer;
    }

    svg.disabled {
      fill: #565656;
      cursor: auto;
    }
  }

  .unavailable {

    .buyButton {
      text-align: center;
      margin-top: 1rem;
    }

    .shareContainerClass {
      margin-top: 1rem;

      .shareBlock, .authBlock {
        margin: auto;
      }

    }
  }
}

@media (max-width: 768px) {
  .player .unavailable .shareContainerClass {
    .shareBlock {
      width: 210px;
    }
    .authBlock {
      width: calc(min(100%, 309px));
    }
  }
}
