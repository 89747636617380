.seeker {
  width: 100%;
  height: 4px;
  background-color: #393939;
  border-top: 1px solid #161616;
  border-bottom: 1px solid #161616;
  margin-top: 1rem;
  margin-bottom: 10px;
  position: relative;
  cursor: pointer;

  .filler {
    height: 2px;
    background-color: #f4f4f4;
  }
}

.duration {
  display: flex;
  justify-content: space-between;
  min-height: 12px;

  .passed, .rest {
    color: #dcdcdc;
    font-size: 12px;
    font-weight: 400;
  }
}
