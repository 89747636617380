.headerActionButton {
  position: relative;
  color: #ffce19;
  font-weight: 900;
  padding: 0 10px;
  width: auto;
  min-width: 72px;
}

.headerSigninButton {
  display: block;
}

.hideNext ~ header {
  display: none;
}

.headerName {
  left: 3rem;
}

@media (max-width: 400px) {
  .headerSigninButton {
    display: none;
  }
}
@media (max-width: 310px) {
  .headerName {
    left: 2rem;
    padding-right: 0rem !important;
  }
}
button[aria-label=SignIn],
button[aria-label=Registration] {
  height: 32px;
}

button[aria-label=SignIn] {
  margin-right: 4px;
  color: #78A9FF;

  &:hover:not(:active) {
    border-color: #353535;
  }
}

button[aria-label=Registration] {
  color: #ffffff;
  background-color: #0F62FE;
  margin-right: 8px;

  &:hover {
    background-color: #0353e9;
  }
}
