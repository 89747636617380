
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 210px);
  grid-gap: 28px;
  justify-content: space-around;

  .item {
    padding-right: 32px;
    padding-bottom: 84px;
  }
}

/* Use a media query to add a breakpoint at 642px: */
@media screen and (max-width: 642px) {
  .grid {
    .item {
      padding-bottom: 24px;;
    }
  }
}
