@import "common";

.progress {
  @include widget;
  height: 100px;
  padding: 10px;
  display: block;
  position: relative;

  .percentOuter {
    @include widget;
    position: absolute;
    left: 0;
    top: 0;
    height: 100px;
    z-index: 20;

    border: solid 10px $black-lighter;
    background: none;
  }

  .blackBorders {
    @include widget;
    position: absolute;
    left: 0;
    top: 0;
    height: 100px;
    z-index: 15;
    border-radius: 0;

    border: 10px solid #161616;
    background: none;
  }

  .percentInner {
    position: absolute;
    left: 0;
    top: 10px;
    background: linear-gradient(90deg, #6340EF 0%, #8348FF 100%);
    height: 80px;
    z-index: 10;
    max-width: 100%;
  }

  @mixin layer {
    @include widget;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: row;
    height: 100px;
    z-index: 30;
    background: none;
    padding: 20px;
  }

  .content {
    @include layer;

    & > svg {
      & > rect {
        fill: rgba(99, 64, 239, 1);

        &:first-child {
          fill: $white;
          stroke: $white;
        }
      }

      & + div {
        margin-left: 14px;
        display: flex;
        flex-direction: column;
        align-self: center;
        flex-grow: 1;

        & > div:first-child {
          font-weight: bold;

          & + div {
            margin-top: 8px;
            opacity: 0.5;
          }
        }

        & + div {
          align-self: center;
          & > svg:hover {
            opacity: 0.8;
            cursor: pointer;
          }
        }
      }
    }
  }

  .stopContent {
    @include layer;
    flex-direction: column;

    & > div:first-child {
      font-weight: bold;
      align-self: center;

      & + div {
        margin-top: 14px;
        display: flex;
        justify-content: space-between;

        & > div {
          text-transform: uppercase;
          border-radius: 10px;
          padding: 10px 15px;
          cursor: pointer;
          &:hover {
            opacity: 0.9;
          }
          &:active {
            opacity: 0.7;
          }
        }

        & > div:first-child {
          background: rgba(238, 67, 44, 1);
          & + div {
            background-color: $white;
            color: #6340EF;
          }
        }
      }
    }
  }
}
