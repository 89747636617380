.notifications {
  padding: 0 1rem;
  font-size: 20px;
  line-height: 26px;

  tr {
    height: 40px;
    td {
      vertical-align: middle;
    }

    td {
      padding-bottom: 18px;
    }

    td.name {
      padding-right: 30px;
    }

    td.email {
      padding-right: 70px;
    }
  }

  tr:first-child {
    padding-bottom: 0;
  }

  tr.writer {
    border-top: 2px solid #565656;
    td {
      padding-top: 1rem;
    }
  }
}

@media (max-width: 445px) {
  .notifications {
    tr td.email {
      padding-right: 30px;
    }
  }
}
