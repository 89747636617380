.topMenu {
  height: 40px;
  display: flex;
  justify-content: flex-end;

  button {
    padding: 0;
    min-height: 40px;
    justify-content: center;
    background-color: #161616;

    svg {
      fill: #ffffff;
    }
  }

  button:hover {
    background-color: #757575;
  }

  .close, .toggle {
    width: 40px;
  }

  .read {
    padding: 4px;
    width: 127px;

    div {
      width: 100%;
      align-items: center;
      display: flex;
      justify-content: space-between;
      border: 1px solid #ffffff;
      padding: 0 1rem;
    }
  }
}
