@import "common";

.container {
  display: flex;
  padding: 0 108px 50px 108px;
  position: absolute;
  overflow: auto;
  top: 0;
  bottom: 0;
  margin: 0;
  padding-top: 80px;
  margin-bottom: 24px;

  .sideContainer {
    display: flex;
    flex-direction: column;
    min-width: 320px;
    max-width: 320px;
    margin-right: 184px;
    max-height: calc(100vh - 98px);
    position: sticky;
    top: 0;

    .descriptionTitle, .bookTitle {
      color: $gray;
      text-transform: uppercase;
      font-family: Roboto, serif;
      font-weight: bold;
      font-size: 13px;
      line-height: 15px;
    }

    .chapter {
      display: flex;
      align-items: center;
      margin-top: 8px;

      & :first-child {
        flex-grow: 1;
        font-size: 24px;
        line-height: 28px;
        color: $white;
      }
    }

    .lineBreak {
      margin-top: 10px;
      background-color: #242425;
      height: 1px;
      border-width: 0px;
    }

    .descriptionTitle {
      margin-top: 50px;
      & + div {
        margin-top: 13px;
        font-family: 'Roboto', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
      }
    }

    & > div:first-child {
      flex-grow: 1;
    }

  }
}

.spaceY {
  margin-top: 1.25rem;
}

.progressBar {
  margin-top: 12px;
  width: 100%;
  display: flex;
  
  .bar {
    height: 6px;
    flex: auto;
    margin-top: 3px;
    margin-right: 16px;
    .content {
      height: 6px;
      border-radius: 3px;
      background-color: #6340EF;
    }
  }

  .label {
  }
}

.characterName {
  padding-top: 50px;
  font-size: 20px;
  font-weight: bold;
}

.characterSelection {
  margin-top: 40px;
  width: 320px;
  border-radius: 10px;
  background-color: #242425;

  .icon {
  }
  .label {
  }
}

.characterLink {
  margin-top: 5px;
}

.hideOthers {
  border-radius: 10px;
  padding-left: 0px;
  background-color: #242425;
  overflow: hidden;
  width: 125px;
  text-align: center;
  margin-top: 40px;
}

.buttons {
  margin-top: 40px;

  .button {
    vertical-align: top;
    display: inline-block;
    background-color: #6340EF;
    padding-left: 15px;
    padding-right: 15px;
    margin-right: 8px;
    border-radius: 10px;
    font-weight: bold;
    font-size: 14px;
  }
  .smallButton {
    display: inline-block;
    vertical-align: top;
    width: 36px;
    height: 36px;
    padding: 4px;
  }
}

.progressSubtitle {
  display: flex;
  justify-content: center;
  align-items: center;
}