.imageContainer {
  width: 226px;
  height: 302px;
  border-radius: 6px;
  overflow: hidden;
  background-size: cover;
  background-position: center;  
  background-color: #2e2e2e;
}

.bookCell {
  width: 226px;
  height: 405px;
  cursor: pointer;
}

.genres {
  padding-top: 15px;
  padding-left: 1px;
  font-size: 11px;
}

.name {
  padding-top: 11px;
  font-size: 16px;
  font-weight: 550;
  line-height: 22px;
  
  // IE
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: calc(2.8em + 9px);
  line-height: 1.4em;

  // Safari
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.writer {
  padding-top: 10px;
  font-size: 12px;
}