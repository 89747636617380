$copy-color: #0F62FE;

.copyLink {
  display: flex;
  height: 24px;
  width: 100%;

  .url {
    font-size: 14px;
    color: #6A6D73;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    // flex-shrink: 1;
    min-width: 0;
    flex: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .button {
    color: #6340EF;
    font-weight: bold;
    cursor: pointer;
  }

  .url + div:hover {
    opacity: 0.75;
  }
}
