.h3 {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.stepsBlock {
  padding: 1rem 0 36px 0;
  background-color: rgba(57, 57, 57, 0.4);

  .h3 {
    margin-top: 0;
  }

  .itemsContainer {
    display: flex;
    flex-wrap: wrap;
    margin-left: -36px;
    margin-right: -36px;
    justify-content: center;

    .item {
      position: relative;
      width: 293px;
      height: 242px;
      border-radius: 26px;
      border: 4px solid rgb(57, 57, 57);
      padding-top: 1rem;
      text-align: center;
      margin-left: 36px;

      .order {
        position: absolute;
        font-size: 26px;
        color: rgb(244, 244, 244);
        left: 157px;
        top: 29px;
      }
    }

    .itemTitle {
      font-size: 1rem;
      line-height: 22px;
      color: rgb(244, 244, 244);
      font-weight: 600;
      margin-bottom: 1rem;
      text-align: left;
      padding-left: 1.5rem;
    }

    .titleCentered {
      text-align: center;
      padding-left: 0;
    }

    .list {
      display: flex;
      padding-left: 23px;
      text-align: left;
      line-height: 20px;

      & > div + div {
        margin-left: 1rem;
      }
    }
  }
}

.timeframeBlock > div > div {
  text-align: center;

  img {
    margin-bottom: 22px;
  }

  div {
    margin-bottom: 50px;
  }
}

@media (max-width: 1050px) {
  .stepsBlock {
    .itemsContainer {
      display: block;

      .item {
        margin: auto;
      }

      .item + .item {
        margin-top: 36px;
      }
    }
  }
}
