.title {
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 1rem;
}

.text {
  max-width: 368px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.16px;
}
