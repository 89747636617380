@import "common";

.uploader {
  @include widget;
  width: 312px;
  height: 220px;
  position: relative;
  padding: 10px;

  .close {
    position: absolute;
    top: 20px;
    right: 20px;

    svg {
      cursor: pointer;
    }

    &:hover svg path {
      opacity: 0.5;
    }
  }

  .dropZone {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.nonActiveZone {
      svg {
        margin-top: 30px;

        & + div {
          margin-top: 17px;
          font-weight: bold;

          & + div {
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 26px;
            text-transform: uppercase;
            opacity: 0.5;

            & + div {
              padding: 10px 15px;
              background: #6340EF;
              border-radius: 10px;
              text-transform: uppercase;
              font-weight: bold;
              &:hover {
                opacity: 0.8;
                cursor: pointer;
              }
              &:active {
                opacity: 0.5;
              }
            }
          }
        }
      }
    }

    &.activeZone {
      justify-content: center;
      border: 2px dashed #6A6D73;
      border-radius: 12px;

      svg + div {
        margin-top: 14px;
        font-weight: bold;
      }
    }
  }
}
