.shareButtonsContainer {
  display: flex;
  margin-top: 1rem;

  .socialItem {
    display: flex;
    flex-direction: column;
    margin-right: 15px;

    .shareButton {
      cursor: pointer;
    }

    .shareButton:hover:not(:active) {
      opacity: 0.75;
    }

    .shareCount {
      align-self: center;
      max-width: 32px;
    }
  }
}
