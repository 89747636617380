.popup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem 1.5rem;
  background-color: #d1d1d1;
  opacity: 0.9;
  border-radius: 5px;
  z-index: 100;
  font-size: 18px;
  font-weight: 500;
  min-width: 40%;

  div {
    margin: auto;
    color: #000;
  }
}
