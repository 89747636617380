
.center {
  margin: auto;
}

.content {
  margin-top: 74px;
  margin-left: 48px;
  margin-right: 48px;
  margin-bottom: 74px;

  i {
    font-style: italic;
  }
}

/* Use a media query to add a breakpoint at 642px: */
@media screen and (max-width: 642px) {
  .content {
    margin-top: 20px;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 20px;
  }
}
