.playlistContainer {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-right: -1rem;

  .chapters {
    line-height: 22px;
    font-weight: 600;
  }

  .line {
    height: 1px;
    background-color: #565656;
    margin-top: 7px;
  }

  .hiddenLine {
    padding: 0 5px;
    margin-left: -5px;
    margin-right: -5px;
  }

  .chapter {
    cursor: pointer;
    display: flex;
    height: 46px;
    align-items: center;

    svg {
      flex-shrink: 0;
      fill: #ffffff;
      margin-right: 10px;
    }

    .chapterName {
      flex-grow: 1;
      display: flex;

      div:first-child {
        flex-shrink: 1;
        line-height: 2rem;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .duration {
      margin-left: 10px;
    }

    svg.unavailable {
      path {
        fill: #c6c6c644;
      }
    }

    .buyButton {
      padding-left: 0;
      padding-right: 0;
      border: 0;
    }

    .buyButton:hover {
      background-color: unset;
    }
  }

  .current {
    background-color: #565656;
    padding: 0 5px;
    margin: 0 -5px;

    svg.pause {
      display: none;
    }

    &:hover {
      svg.speaker {
        display: none;
      }
      svg.pause {
        display: inline;
      }
    }
  }

  .list {
    flex-grow: 1;
    overflow-y: auto;
    padding-right: 1rem;
  }

  .disabled {
    svg {
      fill: #565656;
    }
  }
}
