.staticWrapper {
  img {
    width: 100%;
    max-width: 800px;
  }

  ol {
    list-style-type: none;
  }

  ul {
    list-style-type: initial;
    padding-left: 20px;
  }
}
