.shareBlock {
  width: 210px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  background-color: #F4F4F4;
  border-radius: 5px;
  color: #262626;

  .support {
    font-size: 16px;
    line-height: 22px;
  }

  .info {
    text-align: center;
    margin-bottom: 13px;
  }

  .button {
    width: 150px;
    background-color: #f4f4f4;

    .facebookButton {
      display: flex;
      height: 34px;
      border: 1px solid #1877f2;
      border-left: 0;
      color: #1877f2;
      font-size: 14px;
      cursor: pointer;
      padding: 0;
      width: 100%;
      background: none;
    }

    .twitterButton {
      display: flex;
      height: 34px;
      border: 1px solid #55ACEE;
      border-left: 0;
      color: #55ACEE;
      font-size: 14px;
      text-decoration: none;
    }

    .share {
      flex-grow: 1;
      text-align: center;
      align-self: center;
    }
  }

  .button + .button {
    margin-top: 15px;
  }

}