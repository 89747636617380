.base {
  display: flex;

  .buttons {
    width: 100%;
    max-width: 222px;
    flex-shrink: 0;

    button {
      width: 100%;
      color: #f4f4f4;
      font-size: 20px;
    }
  }

  .content {
    flex-basis: 100%;
    padding: 1rem;
    font-size: 20px;
    line-height: 26px;
    color: #f4f4f4;
    font-weight: 400;
  }
}

@media (max-width: 42rem) {
  .base .buttons {
    display: none;
  }
}
