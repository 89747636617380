@import '@carbon/themes/scss/themes';

$carbon--theme: $carbon--theme--g100;
@include carbon--theme();

$feature-flags: (
        ui-shell: true,
        grid-columns-16: true
);



@import 'carbon-components/scss/globals/scss/styles.scss';

:root {
  .bx--tile {
    background-color: #202020;
  }

  main {
    overflow: hidden;
  }

  ul[aria-label=chapters-ov].bx--overflow-menu-options {
    padding-left: 0;
  }
}
