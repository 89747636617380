.chapterName {
  margin-top: 1rem;
  font-weight: 600;
  font-size: 1rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 18px;
  min-height: 18px;
}
