.brand {
  margin-top: 2rem;
  text-align: center;
}

.card {
  max-width: 600px;
  margin: 2rem auto;

  h2 {
    padding: 1rem 2rem;
  }
}

.form {
  padding: 1rem;

  button {
    width: 100%;
  }
}



@media (min-width: 42rem) {
  .card {
    h2 {
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }
}
