.pitchSlider {
  //height: 20px;
}

.charError {
  text-align: center;
  color: #fa4d56;
}

.narrator {
  padding-left: 1rem;
  height: 40px;
  display: flex;
  align-items: center;

  & span[class="bx--toggle__switch"] {
    margin-top: 0;
  }
}

.deleteBlock {

  & .deleteLink {
    color: #55ACEE;
    font-weight: bold;
    cursor: pointer;

    svg {
      vertical-align: text-top;
    }
  }
}

@media (min-width: 42rem) {
  .narrator {
    padding-left: 1.5rem;
  }
}
