.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 1000;

  .container {
    margin: auto;
    width: 306px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background-color: rgba(209, 209, 209, 0.9);
    color: #242424;
    font-size: 1rem;
    font-weight: 500;
    padding: 8px 16px 12px 16px;

    .textContainer {
      text-align: center;
      margin: auto auto 14px;
    }

    .progressContainer {
      width: 100%;
      height: 2px;
      background-color: #e0e0e0;
      position: relative;

      .progress {
        height: 2px;
        background-color: #0F62FE;
      }
    }
  }
}
