.bookCard {
  width: 230px;
  height: 400px;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0 1rem 1rem;

  .poster {
    margin: 1rem;
    height: 259px;
    line-height: 259px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    flex-shrink: 0;
  }

  .desc {
    padding: 0.5rem;
    line-height: 1.5rem;
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
      font-weight: 600;
      font-size: 1.125rem;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .counters {
      display: flex;
      justify-content: space-between;
    }

    .toolbar {
      display: flex;
      justify-content: space-between;
    }
  }

}

// .title {
//   margin-bottom: 8px;
//   line-height: 22px;
  
//   // IE
//   display: block;
//   text-overflow: ellipsis;
//   overflow: hidden;
//   max-height: 2.8em;
//   line-height: 1.4em;

//   // Safari
//   overflow: hidden;
//   text-overflow: ellipsis;
//   display: -webkit-box;
//   -webkit-line-clamp: 2;
//   -webkit-box-orient: vertical;
// }

// .time {
//   margin-bottom: 12px;
// }

// .summary {  
//   // IE
//   display: block;
//   text-overflow: ellipsis;
//   overflow: hidden;
//   max-height: 2.8em;
//   line-height: 1.4em;

//   // Safari
//   overflow: hidden;
//   text-overflow: ellipsis;
//   display: -webkit-box;
//   -webkit-line-clamp: 2;
//   -webkit-box-orient: vertical;
// }

// .image {
//   margin: auto;
//   height: 200px;
//   max-width: 210px;
//   border-radius: 5px;
//   margin-bottom: 16px;

//   background-size: cover;
//   background-position: center;  
// }

// /* Use a media query to add a breakpoint at 642px: */
// @media screen and (max-width: 642px) {

// }
