.page {

  .container {
    width: 100%;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    .formBlock {
      max-width: 562px;
      margin: 0 auto;

      .title {
        margin: 0 2rem 13px 2rem;
        font-size: 28px;
        line-height: 36px;
      }

      .form {
        position: relative;
        background-color: #262626;
        padding: 2rem 2rem 42px 2rem;

        .close {
          svg {
            position: absolute;
            right: 1rem;
            top: 1rem;
          }
          &:hover > svg {
            fill: #6F6F6F;
          }
        }
      }
    }
  }
}

@media (max-width: 25rem) {
  .form {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .title {
    margin-left: 0.5rem !important;
  }
}
