.header {
    background-size: cover;
    background-position: center;  
    width: 100vw;
    height: 25.78vw;
    max-height: 264px;
    min-height: 208px;
    position: relative;
  
    .titleContainer {
      position: absolute;
      left: 40px;
      bottom: 26px;
      width: calc(100% - 80px);
      vertical-align: middle;
  
      .title {
        font-weight: 700;
        font-size: 42px;
        display: inline-block; // display inline with ability to provide width/height
        vertical-align: middle;
        padding-bottom: 16px;
      }
      .subtitle {
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;
        
      
      }
    }

    .share {
      position: relative;
      right: 0px;
      width: auto;
      max-width: 100%;
      direction: rtl;
      padding-left: 16px;
    }
  }
  
  .clearTitle {
    font-weight: 700;
    font-size: 42px;
    padding-left: 48px;
    padding-top: 64px;
    padding-bottom: 0px;
  }
  
  /* Use a media query to add a breakpoint at 642px: */
@media screen and (max-width: 642px) {
    .header {
      .titleContainer {
        left: 16px;
        bottom: 16px;
        width: 100%;
        width: calc(100% - 48px);
  
        .title {
          display: block;
          padding-bottom: 24px;
          font-size: 32px;
        }
      } 
      
      .share {
      }
    }
  
    .clearTitle {
      font-size: 32px;
      padding-left: 16px;
      padding-top: 32px;
      padding-bottom: 16px;
    }
  }
  