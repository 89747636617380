.soundError {
  text-align: center;
  color: #fa4d56;
}

.mp3Uploader {
  min-height: unset;
  height: 32px;
}

.mp3Container {
  display: flex;
  align-items: center;
}