.h2Subtitle {
    color: #757575;
}

.bodyColor {
    color: white;
}

.contentColor {
    background-color: #171717;;
}

.content {
    filter: invert(1);
    img {
        filter: invert(1);
    }
    iframe {
        filter: invert(1);
    }
}

.button {
    svg {
        margin-left: 0.5rem;
        fill: #ffffff;
    }
    
    &:hover, &:active, &:focus {
        svg {
            fill: #AAAAAA;
        }
    }
}

.button_cta {
    background-color: #2C66DA;

}

.button_normal {
    background-color: #343537;
}