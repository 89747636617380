@import '@carbon/themes/scss/themes';

$carbon--theme: $carbon--theme--g100;
@include carbon--theme();

.base {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
  max-height: 34px;

  .likes {
    margin-right: 0.2rem;
    display: inline-block;
    vertical-align: middle;
  }

  .icon {
    display: inline-block;
    vertical-align: middle;
    padding-right: 6px;
  }
  
  svg {
    stroke: $text-01;
    fill: $text-01;
  }
}

.liked {
  color: #ff414b;

  svg {
    stroke: $danger;
    fill: $danger;
  }
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
  cursor: pointer;
}

.speechBubble {
  position: absolute;
  background: #F4F4F4;
  border-radius: .4em;
  width: 203px;
  height: 155px;
  z-index: 3;
  top: 5px;
  left: -89px;
}

.speechBubble:after {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 8px solid transparent;
  border-bottom-color: #F4F4F4;
  border-top: 0;
  margin-left: -8px;
  margin-top: -8px;
}

@media (max-width: 500px) {
  .speechBubble:after {
    left: 16px;
    left: 10%;
  }
  .speechBubble {
    left: calc(-10% - 8px);
  }
}
