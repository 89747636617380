.chapterName {
  font-size: 20px;
  line-height: 26px;
  text-align: center;
}

.light {
  background-color: #fff;

  .chapterName {
    color: #000;
  }
}

.dark {}

.relativeContainer {
  .shareBlock, .authReqBlock {
    margin: 24.5px auto auto;
  }
}

@media (min-height: 700px) {
  .relativeContainer {

    .shareBlock, .authReqBlock {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
