.center {
  margin: auto;
  max-width: 1024px;
}

.content {
  margin-top: 96px;
  margin-left: 48px;
  margin-right: 48px;
}

.sectionHeader {
  padding-bottom: 24px;
  display: inline-block;
}

.row {
  padding-bottom: 84px;
}

.listCell {
  display: flex;
  flex-direction: column;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 210px);
  grid-gap: 28px;
  justify-content: space-around;

  .item {
    padding-right: 32px;
    padding-bottom: 84px;
  }
}

.selection {
  display: inline-block;
  float: right;

  .dropdown {
    svg {
      fill: #6DA4FD;
    }
  }
}

/* Use a media query to add a breakpoint at 642px: */
@media screen and (max-width: 642px) {
  .content {
    margin-top: 20px;
    margin-left: 16px;
    margin-right: 16px;
  }

  .grid {
    display: block;

    .item {
      width: 100%;
      padding-right: 0px;
      padding-bottom: 24px;
    }
  }

  .row {
    padding-bottom: 56px;
  }  
}
