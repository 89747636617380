.twoColumn {
  display: flex;

  .column1 {
    flex-basis: 146px;
    min-width: 105px;
    font-size: 20px;
    line-height: 26px;
    flex-shrink: 1;
    padding-top: 6px;
  }

  .column2 {
    .fields {
      display: flex;
      margin-bottom: 11px;

      .password {
        margin-right: 20px;
      }

      .confirm {}
    }

    .hint {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.32px;
      margin-bottom: 12px;
    }

    .submit {
      width: 203px;
    }
  }
}

.error {
  color: #fa4d56;
  margin-bottom: 0.5rem;
}
